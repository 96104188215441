"use strict";

document.addEventListener("DOMContentLoaded", function () {
	
    // START -- Add attributes to outer links
    const links = Array.from(document.querySelectorAll('a[href^="http://"], a[href^="https://"]'));
    const currentHostname = window.location.hostname;

    links.forEach(function (link) {
        if (!link.href.includes(currentHostname)) {
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "nofollow");
        }

        if (link.href.endsWith(".pdf") || link.href.endsWith(".html")) {
            link.setAttribute("target", "_blank");
        }
    });
    // END -- Add attributes to outer links

    // START -- Back to top management
    const backToTopButton = document.querySelector(".back-to-top");
    if (backToTopButton) {
        backToTopButton.addEventListener("click", function () {
            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
    // END -- Back to top management

    // START - Scroll to anchor
    function gotoAnchor(anchor, isDataAnchor = false) {
        if (isDataAnchor) {
            anchor = `[data-anchor="${anchor}"]`;
        }

        const targetElement = document.querySelector(anchor);
        if (targetElement) {

            // Devo controllare se lo scroll va verso l'alto. Se verso l'alto devo scrollare di più aggiungendo l'altezza della navbar
            let offset = 0;
            
            if (window.scrollY > targetElement.getBoundingClientRect().top) {
                const pageHeader = document.querySelector("#pageHeader");

                if (pageHeader) {
                    offset = pageHeader.offsetHeight + 20;
                }

            }

            const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({ top: targetPosition, behavior: "smooth" });

        }
    }

    const anchorButtons = Array.from(document.querySelectorAll(".btn-anchor"));
    anchorButtons.forEach(function (button) {
        let anchor = null;
        if (button.getAttribute("href") === null) {
            anchor = button.getAttribute("data-anchor");
        } else {
            anchor = button.getAttribute("href");
        }

        button.addEventListener("click", function () {
            gotoAnchor(anchor);
        });
    });
    // END - Scroll to anchor

    // START - Scroll to anchor
    // Controllo se c'è un hash nell'url e se esiste un elemento con quell'id, oppure un data-anchor
    if (window.location.hash) {
        // Rimuovo il carattere # e controllo se esiste un elemento con quell'id o un data-anchor
        let hash = window.location.hash.replace("#", "");

        setTimeout(function () {
            if (document.getElementById(hash) || document.querySelector(`[data-anchor="${hash}"]`)) {
                gotoAnchor(hash, true);
            }
        }, 100);
    }
    // END - Scroll to anchor
});
